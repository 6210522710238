<template>
  <div>
    <b-card no-body>
      <div class="p-2">
        <h6>แบบสอบถามผู้ใช้ได้ทราบหรือค้นพบเว็บไซต์ผ่านช่องทางใด</h6>
      </div>
      <b-overlay :show="loading">
        <b-table responsive="sm" :items="listReport" :fields="fields" hover>
          <template #cell(value)="data">
            <div class="d-flex align-items-center" style="gap: 0.2rem">
              <b-img v-if="cFormatIcon(data.value)" :src="cFormatIcon(data.value)" alt="" class="mr-50" height="20" />

              <span>{{ cFormatLabel(data.value) }}</span>
            </div>
          </template>

          <template #cell(count)="data">
            <span>
              {{ gFormatNumberToCurrency(data.value) }}
            </span>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listReport: [],
      loading: false,
      fields: [
        { key: 'value', label: 'ตัวเลือก' },
        {
          key: 'web',
          label: 'ตอบผ่านเว็บ (ครั้ง)',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'app',
          label: 'ตอบผ่านแอป (ครั้ง)',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      formatLabelAndIcon: {
        facebook: {
          label: 'Facebook',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/facebook-social.svg'),
        },
        google: {
          label: 'Google',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/google-social.svg'),
        },
        tiktok: {
          label: 'TikTok',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/tiktok-social.svg'),
        },
        line: {
          label: 'Line',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/line-social.svg'),
        },
        'friend-acquaintances': {
          label: 'คนรู้จัก-เพื่อน',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/users.svg'),
        },
        youtube: {
          label: 'Youtube',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/youtube-social.svg'),
        },
        tv: {
          label: 'โทรทัศน์',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/television.svg'),
        },
        radio: {
          label: 'วิทยุ',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/color/radio.svg'),
        },
        other: {
          label: 'อื่นๆ',
          icon: null,
        },
      },
    }
  },
  computed: {
    cFormatLabel() {
      return value => this.formatLabelAndIcon[value]?.label ?? '-'
    },
    cFormatIcon() {
      return value => this.formatLabelAndIcon[value]?.icon ?? null
    },
  },
  mounted() {
    this.fetchReportUDCS()
  },
  methods: {
    async fetchReportUDCS() {
      this.loading = true
      const resp = await this.api.getV2('api/admin/recommended-from/index').catch(() => null)
      this.loading = false
      if (resp && resp.code === 200) {
        this.listReport = [...resp.data]
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
