<template>
  <div>
    <h4>สรุปรายงานแบบสอบถาม</h4>
    <br />
    <ReportSurveyUDCS />
  </div>
</template>

<script>
import ReportSurveyUDCS from './components/ReportSurveyUDCS.vue'

export default {
  components: {
    ReportSurveyUDCS,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
